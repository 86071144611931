import { useGLTF } from "@react-three/drei"
import { DragControls } from "@react-three/drei"

export default function Carpet() {
    const { nodes, materials } = useGLTF('./carpet.glb')
    // Carpet_white, Carpet_patterned
    return <mesh
        name="carpet"
        geometry={nodes.Carpet1_low_Carpet_white_0.geometry}
        material={materials.Carpet_white}
        material-color='#B89FF5'
        scale={[3, 4.5, 1]}
        rotation={[- Math.PI * 1 / 2, 0, - Math.PI * 0 / 8]}
        position-y={-1}
        receiveShadow={true}
    />
}

export default function Lights(props) {

    return <>
        <directionalLight
            position={[1, 2, 3]}
            intensity={1.5}
            castShadow // highlight-line
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
            shadow-radius={2}
        />
        <directionalLight
            position={[-2, 2, 3]}
            intensity={1.5}
            castShadow // highlight-line
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
            shadow-radius={2}
        />
        <ambientLight intensity={1.0} />
    </>
}